<template>
  <div class="signIn">
    <div class="body">
      <div class="separation" />
      <div class="options">
        <Guest class="options__1" />
        <Scan class="options_2 " v-show="usingBarcode" />
        <Customer class="options__3" />
      </div>
    </div>
    <Button :colorType="3" class="go-back" @click="goHome" iconGroup="fas" iconName="arrow-left" label="Back">
    </Button>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'
import InputComponent from '@/components/Input/Input.vue'
import { mapState } from 'pinia';
import { useCommonStore } from '@/stores/common';
import Guest from './Guest/Guest.vue';
import Scan from './Scan/Scan.vue';
import Customer from './Customer/Customer.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    Button,
    InputComponent,
    Guest,
    Scan,
    Customer,
    FontAwesomeIcon
  },
  computed: {
    ...mapState(useCommonStore, {
      store: 'getStore',
    }),
    logo() {
      if (this.store.logo) {
        return this.store.logo.secure_url;
      }
      return "";
    },
    usingBarcode() {
      return this.store.usingBarcode
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './_options.scss';
</style>