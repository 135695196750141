
import ProductCard from '@/components/ProductCard/ProductCard.vue';
import { mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';

export default {
  props: {
    products: {
      type: Object
    }
  },
  components: {
    ProductCard
  },
  methods: {
    ...mapActions(useCommonStore, ['selectProduct']),
    onClick(product: unknown) {
      this.selectProduct(product);
    }
  }
};
