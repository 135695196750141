<template>
  <button class="category-btn" :class="{ 'category-btn--selected': isSelected }" @click="onCategorySelected()">
    {{ name.toUpperCase() }}
  </button>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(useCommonStore, {
      selectedCategory: 'getSelectedCategory'
    }),
    isSelected() {
      return this.name === this.selectedCategory;
    }
  },
  methods: {
    ...mapActions(useCommonStore, ['selectCategory']),
    onCategorySelected() {
      this.selectCategory(this.name)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_categoryBtn.scss';
</style>
