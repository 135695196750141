<template>
  <div class="rec-limits">
    <div class="limit" v-for="(limit, index) of limits" :key="index">
      <p class="limit__title">{{ limit.name }}</p>
      <p class="limit__sub-title">{{ limit.current ?? 0 }} / {{ inState ? limit.inState : limit.outOfState }}</p>
    </div>
  </div>
</template>

<script>
import { useCommonStore } from '@/stores/common';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia'

export default {
  computed: {
    ...mapState(useCommonStore, ['getStoreState']),
    ...mapState(useUserStore, ['getCustomerState', 'getRecLimits']),
    inState() {
      return this.getCustomerState == this.getCustomerState;
    },
    limits() {
      return this.getRecLimits;
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_recLimits.scss';
</style>
