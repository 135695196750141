<template>
  <div class="base-modal__container" @click="$emit('toggle-modal', { isOpen: false })">
    <div class="base-modal__body" :class="{ 'base-modal__body--auto-width': autoWidth }" @click.stop="">
      <div class="base-modal__header">
        <div class="base-modal__header-title">
          <h2>
            {{ title }}
          </h2>
          <span v-if="subtitle">
            {{ subtitle }}
          </span>
        </div>
        <div class="base-modal__header-close">
          <font-awesome-icon @click="$emit('toggle-modal', { isOpen: false })" :icon="['fas', 'times']" />
        </div>
      </div>
      <slot />
      <!-- <div
        class="base-modal__close-button"
        @click="$emit('toggle-modal', { isOpen: false })"
      >
        <i class="el-icon-close"></i>
        Close
      </div> -->
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "BaseModal",
  components: { FontAwesomeIcon },
  props: {
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    autoWidth: { type: Boolean, default: false },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  created() {
    window.addEventListener(
      "keydown",
      this.keyDownHandler
    )
  },
  methods: {
    keyDownHandler(e) {
      if (e.keyCode === 27) {
        this.$emit('toggle-modal', { isOpen: false })
      }
    },
  }
};
</script>

<style lang="scss">
@import "base-modal";
</style>
