<template>
  <Skeleton v-if="isLoading" />
  <nav class="nav" v-else>
    <BaseModal v-if="logOutModalOpen" @toggle-modal="toggleModal" title="Are you sure you want to logout?"
      :autoWidth="true">
      <div class="modal-actions">
        <Button @click="() => logOutModalOpen = false" label="CANCEL" />
        <Button @click="performLogout" :colorType="1" label="CONFIRM" />
      </div>
    </BaseModal>
    <div class="actions">
      <Button iconGroup="'fas'" :iconName="'filter'" @click="flagFilter" :highlight="isFilterSelected" />
      <Button :iconGroup="'fas'" :iconName="'sort'" @click="flagSort" :highlight="isSortSelected" />
      <div class="taxes-included">Taxes Included</div>
    </div>
    <Search :value="search" @update="updateSearch" @clear="onClearSearch" ref="searchComponent" />
    <!-- <input placeholder="search" /> -->
    <div class="status">
      <button v-if="isLoggedIn" class="status__logout" @click="() => logOutModalOpen = true">LOGOUT</button>
      <CartBtn v-if="isLoggedIn" @click="flagCart" :count="getProductsCountInCart" />
      <div v-if="isViewOnly"></div>
      <Button v-else-if="!isLoggedIn" @click="goToScan" label="START" :highlight="true" class="start" />
    </div>
    <!-- <router-link to="/about">About</router-link> -->
    <div class="categories">
      <div class="categories__scroll">
        <CategoryBtn v-for="(category, index) of categories" :key="index" :name="category"></CategoryBtn>
      </div>
    </div>
  </nav>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import Search from '@/components/Search/Search.vue';
import { mapState, mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { useUserStore } from '@/stores/user';
import CategoryBtn from './CategoryBtn/CategoryBtn.vue'
import CartBtn from './CartBtn/CartBtn.vue';
import Skeleton from './Skeleton/Skeleton.vue'
import BaseModal from "@/components/BaseModal/BaseModal.vue";

export default {
  components: {
    Button,
    Search,
    CategoryBtn,
    CartBtn,
    Skeleton,
    BaseModal
  },
  data() {
    return {
      logOutModalOpen: false
    }
  },
  computed: {
    ...mapState(useCommonStore, {
      categories: 'getCategoriesName',
      actions: 'getActions',
      getLoadingState: 'getLoadingState',
      getIsViewOnly: 'getIsViewOnly',
      getSearch: 'getSearch'
    }),
    ...mapState(useUserStore, {
      isLoggedIn: 'isLoggedIn',
      getProductsCountInCart: 'getProductsCountInCart'
    }),
    search() {
      return this.getSearch
    },
    isViewOnly() {
      return this.getIsViewOnly
    },
    isFilterSelected() {
      const filterOptions = this.actions.filter.options;
      let filterSelected = false;
      filterOptions.forEach(option => {
        const selected = option.items.some(i => i.isSelected == true);
        if (selected) {
          filterSelected = true
        }
      })
      return filterSelected;
    },
    isLoading() {
      return this.getLoadingState.getSettings || this.getLoadingState.getProducts
    },
    isSortSelected() {
      const sortOptions = this.actions.sort.options;
      return sortOptions.some(o => o.sort != null);
    },
  },
  methods: {
    ...mapActions(useCommonStore, ['flagSort', 'flagFilter', 'setSearch']),
    ...mapActions(useUserStore, ['flagCart', 'logout']),
    goToScan() {
      this.$router.push('/signIn');
    },
    performLogout() {
      this.logOutModalOpen = false;
      this.logout()
    },
    toggleModal(event) {
      this.logOutModalOpen = event.isOpen;
    },
    onClearSearch() {
      this.setSearch('')
    },
    updateSearch(value) {
      this.setSearch(value)
    },
    setSearchFocus() {
      // setInterval(() => {
      //   const route = this.$router.currentRoute.value.name;
      //   const component = this.$refs.searchComponent
      //   if (route == 'home' && component) {
      //     component.setFocus()
      //   }
      // }, 5000)
    }
  },
  mounted() {
    this.setSearchFocus()
  }
};
</script>

<style lang="scss" scoped>
@import './_header.scss';
</style>
