<template>
  <button class="base-button" v-bind="$attrs" :class="`${baseButtonColor}`">
    <div v-show="isLoading">
      <font-awesome-icon :icon="['fa', 'fa-circle-notch']" spin />
    </div>
    <div v-show="!isLoading">
      <font-awesome-icon :class="{ 'base-button__icon': label && label.length > 0 }" v-if="isIconValid"
        :icon="[iconGroup, iconName]" />
      <span v-if="label" class='base-button__label'>
        {{ label }}</span>
      <slot />
    </div>
  </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'Button',
  props: {
    iconGroup: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    },
    label: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    colorType: {
      type: Number,
      default: -1
    },
    highlight: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FontAwesomeIcon
  },
  computed: {
    isIconValid() {
      return (
        this.iconGroup.trim().length > 0 && this.iconName.trim().length > 0
      );
    },
    colorFormat() {
      if (this.highlight) {
        return 1;
      }
      return this.colorType
    },
    baseButtonColor() {
      switch (this.colorFormat) {
        case 1:
          return 'base-button--green'
        case 2:
          return 'base-button--green-dark'
        case 3:
          return 'base-button--gray'
        default:
          return ''
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './_button.scss';
</style>
