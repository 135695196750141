<template>
  <div class="no-data">
    <img class="image" src="@/assets/images/thinking-face.svg" alt="No data">
    <p class="title">No Products Found</p>
    <p class="sub-title">There are no products that match your current filters. Try removing some of them to get better
      results.</p>
  </div>
</template>

<style lang="scss" scoped>
@import './_noData.scss';
</style>
