import { defineStore } from 'pinia';
import axiosService from '@/services/axiosService';
import {
  getOrderTypeFromCustomer,
  isRecreationalCustomer
} from './helpers/userHelper';
import SaleType from '@/types/SaleType';

export const useUserStore = defineStore('user', {
  state: () => ({
    loadingStatus: {
      getQueueCustomer: false,
      createOrder: false,
      getOpenOrder: false,
      checkout: false,
      updateCart: false,
      userLogin: false,
      addGuestCustomer: false
    },
    isGuest: false as boolean,
    user: null as any,
    cart: {
      isOpen: false,
      order: {} as any,
      soldOutProducts: [] as string[]
    } as any
  }),
  getters: {
    getCart: (state): any => state.cart,
    isLoggedIn: (state): boolean => state.user != null,
    customerId: (state): string => state.user?.customer?._id,
    getCustomerState: (state): string =>
      state.cart?.order?.queueCustomer?.customerInfo?.state,
    getRecLimits: (state): any => state.cart?.order?.recreationalLimits,
    getProductsInCart: (state): any => {
      const products = state.cart.order?.products;
      if (!products) return [];
      return products.map((p: any) => {
        return { _id: p._id, quantity: p.quantity };
      });
    },
    getProductsCountInCart: (state): number => {
      let count = 0;
      const products = state.cart.order?.products;
      if (!products) return count;
      products.forEach((product: any) => {
        count += product.quantity;
      });
      return count;
    },
    getIsGuest: (state): boolean => state.isGuest,
    getLoadingStatus: (state): any => state.loadingStatus,
    getOrderPaymentStatus: (state): string | null => state.cart.order.payment,
    getSoldOutProducts: (state): string[] => state.cart.soldOutProducts
  },
  actions: {
    logout() {
      this.user = null;
      this.cart.order = {};
      this.isGuest = false;
      this.cart.soldOutProducts = [];
    },
    flagCart() {
      this.cart.isOpen = !this.cart.isOpen;
    },

    async addGuestCustomer(
      name: string,
      lastName: string,
      country: string,
      state: string
    ) {
      return new Promise((resolve, reject) => {
        this.loadingStatus.addGuestCustomer = true;
        const payload = {
          name: name,
          lastName: lastName,
          country: country,
          state: state,
          orderType: 'recreational'
        };
        try {
          axiosService.post(
            `/addGuestCustomer`,
            payload,
            (response: any, error: any) => {
              this.loadingStatus.addGuestCustomer = false;
              if (error) {
                const message = error.message ?? error;
                reject(message);
                return;
              }
              const data = response.data.body;
              if (!isRecreationalCustomer(data)) {
                // TODO: this is temporary since we are enablining the kiosk for recreational  only
                reject('Customer not enabled for purchase');
                return;
              }
              this.user = data;
              resolve(data);
            }
          );
        } catch (err) {
          this.loadingStatus.addGuestCustomer = false;
          console.log(err);
          reject(err);
        }
      });
    },
    async userLogin(email: string, password: string) {
      return new Promise((resolve, reject) => {
        this.loadingStatus.userLogin = true;
        const payload = {
          email: email,
          password: password
        };
        try {
          axiosService.post(
            `/userLogin`,
            payload,
            (response: any, error: any) => {
              this.loadingStatus.userLogin = false;
              if (error) {
                const message = error.message ?? error;
                reject(message);
                return;
              }
              const data = response.data.body;
              if (!isRecreationalCustomer(data)) {
                // TODO: this is temporary since we are enablining the kiosk for recreational  only
                reject('Customer not enabled for purchase');
                return;
              }
              this.user = data;
              resolve(data);
            }
          );
        } catch (err) {
          this.loadingStatus.userLogin = false;
          console.log(err);
          reject(err);
        }
      });
    },
    async getQueueCustomer(code: string) {
      return new Promise((resolve, reject) => {
        this.loadingStatus.getQueueCustomer = true;
        try {
          axiosService.get(
            `/getQueueCustomer?code=${code}`,
            (response: any, error: any) => {
              this.loadingStatus.getQueueCustomer = false;
              if (error) {
                const message = error.message ?? error;
                reject(message);
                return;
              }
              const data = response.data.body;
              if (!isRecreationalCustomer(data.customer)) {
                // TODO: this is temporary since we are enablining the kiosk for recreational  only
                reject('Customer not enabled for purchase');
                return;
              }
              this.user = data;
              resolve(data);
            }
          );
        } catch (err) {
          this.loadingStatus.getQueueCustomer = false;
          console.log(err);
          reject(err);
        }
      });
    },
    async saveOrder() {
      return new Promise((resolve, reject) => {
        try {
          axiosService.post(
            `/saveOrder`,
            this.cart.order,
            (response: any, error: any) => {
              if (error) {
                const message = error.message ?? error;
                reject(message);
                return;
              }
              const data = response.data.body;
              this.cart.order = data;
              resolve(data);
            }
          );
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
    async cancelOrder() {
      return new Promise(async (resolve, reject) => {
        try {
          let payload = {
            mode: 'cancel',
            id: this.cart.order._id,
            order: this.cart.order
          };

          axiosService.post(
            `/cancel`,
            payload,
            async (response: any, error: any) => {
              if (error) {
                const message = error.message ?? error;
                reject(message);
                return;
              }
              const data = response.data.body;
              resolve(data);
            }
          );
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
    async reverseOrder() {
      return new Promise(async (resolve, reject) => {
        try {
          const order = JSON.parse(JSON.stringify(this.cart.order));
          order._entity = 'order';

          const payload = {
            order
          };
          axiosService.post(
            `/reverseOrder`,
            payload,
            async (response: any, error: any) => {
              if (error) {
                const message = error.message ?? error;
                reject(message);
                return;
              }
              const data = response.data.body;
              this.cart.order = data;
              resolve(data);
            }
          );
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
    async createOrder() {
      return new Promise(async (resolve, reject) => {
        this.loadingStatus.createOrder = true;
        try {
          let payload = {
            _id: 'NEW',
            _entity: 'order',
            type: 'recreational',
            customer: this.user?.customer,
            queueCode: this.user.code,
            source: 'kiosk',
            queueCustomer: this.user.queueCustomer,
            setQueueCustomer: true
          };
          axiosService.post(
            `/createOrder`,
            payload,
            async (response: any, error: any) => {
              if (error) {
                const message = error.message ?? error;
                reject(message);
                return;
              }
              const data = response.data.body;
              data.isRetail = this.user.customer ? false : true;
              data.type = getOrderTypeFromCustomer(this.user.accountType);
              this.cart.order = data;

              await this.saveOrder().then((data) => {
                this.loadingStatus.createOrder = false;
                resolve(data);
              });
            }
          );
        } catch (err) {
          this.loadingStatus.createOrder = false;
          console.log(err);
          reject(err);
        }
      });
    },
    async getOpenOrder() {
      return new Promise((resolve, reject) => {
        this.loadingStatus.getOpenOrder = true;
        const payload = {
          customer: this.user.customer,
          queueCode: this.user.code,
          saleType: SaleType.RECREATIONAL // TODO; to modify this since we are doing recreational just for now
        };
        try {
          axiosService.post(
            `/getOpenOrder`,
            payload,
            (response: any, error: any) => {
              this.loadingStatus.getOpenOrder = false;
              if (error) {
                const message = error.message ?? error;
                reject(message);
                return;
              }
              const data = response.data.body;
              if (data) {
                this.cart.order = data;
              }
              resolve(data);
            }
          );
        } catch (err) {
          this.loadingStatus.getOpenOrder = false;
          console.log(err);
          reject(err);
        }
      });
    },
    async checkout() {
      return new Promise((resolve, reject) => {
        const order = JSON.parse(JSON.stringify(this.cart.order));
        this.cart.soldOutProducts = [];
        order.isCheckingOut = true;
        order.payment = 'pre-order';
        order._entity = 'order';
        try {
          this.loadingStatus.checkout = true;
          axiosService.post(`/checkout`, order, (response: any, error: any) => {
            this.loadingStatus.checkout = false;
            if (error) {
              const body = error?.body;
              if (body && Array.isArray(body)) {
                this.cart.soldOutProducts = body.map(
                  (p: any) => p.externalFields?.product?._id
                );
              }
              const message = error.message ?? error;
              reject(message);
              return;
            }
            const data = response.data.body;

            resolve(data);
          });
        } catch (err) {
          this.loadingStatus.checkout = false;
          console.log(err);
          reject(err);
        }
      });
    },
    async updateCart(productId: string, quantity: number) {
      const action = {
        mode: quantity > 0 ? 'add' : 'subtract',
        productId,
        quantity: Math.abs(quantity)
      };
      const order = this.$state.cart.order;
      return new Promise((resolve, reject) => {
        try {
          this.loadingStatus.updateCart = true;
          axiosService.post(
            `/updateCart`,
            { action, order },
            (response: any, error: any) => {
              this.loadingStatus.updateCart = false;
              if (error) {
                let message = error.message ?? error;
                if (error.errorCode == 9001) {
                  message =
                    'Please ask the staff to make this product available to purchase.';
                }
                reject(message);
                return;
              }
              const data = response.data.body;
              this.cart.order = data;
              resolve(data);
            }
          );
        } catch (err) {
          this.loadingStatus.updateCart = false;
          console.log(err);
          reject(err);
        }
      });
    },
    setIsGuest(isGuest: boolean = false) {
      this.isGuest = isGuest;
    }
  }
});
