<template>
  <VueSidePanel
    v-model="isOpen"
    hide-close-btn
    side="right"
    width="50vw"
    panel-color="#1b1b1d"
  >
    <template v-slot:header>
      <Header @onClose="onClose()" />
    </template>
    <template #default>
      <div class="container">
        <div class="empty" v-if="productsInCart.length == 0">
          <p>Empty</p>
        </div>
        <div class="products-list" v-else>
          <RecLimits />
          <Product
            class="products-list__item"
            v-for="(product, index) of productsInCart"
            :key="index"
            :product="product"
            :isSoldOut="isSoldOut(product._id)"
            @click="onProductSelect(product)"
          />
        </div>
        <Summary
          :subTotal="order.subTotal"
          :total="order.orderTotal"
          :tax="order.tax"
          :discount="order.discount"
          :canComplete="productsInCart.length > 0"
          :isPreorder="isPreorder"
          @complete="complete"
          @edit="edit"
          :isLoading="isLoading"
          :taxesIncluded="taxesIncluded"
          @continueShopping="continueShoppingHandler"
          :isGuest="getIsGuest"
        />
      </div>
    </template>
  </VueSidePanel>
</template>

<script>
import { VueSidePanel } from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/stores/user';
import Header from './Header/Header.vue';
import Button from '@/components/Button/Button.vue';
import Product from './Product/Product.vue';
import { useCommonStore } from '@/stores/common';
import Summary from './Summary/Summary.vue';
import Payment from '@/types/Payment';
import RecLimits from '@/components/RecLimits/RecLimits.vue';

export default {
  components: {
    VueSidePanel,
    Header,
    Button,
    Product,
    Summary,
    RecLimits
  },
  computed: {
    ...mapState(useUserStore, {
      cart: 'getCart',
      getOrderPaymentStatus: 'getOrderPaymentStatus',
      getLoadingStatus: 'getLoadingStatus',
      getIsGuest: 'getIsGuest',
      soldOutProducts: 'getSoldOutProducts'
    }),
    ...mapState(useCommonStore, {
      categories: 'getCategories',
      store: 'getStore'
    }),
    productsInCart() {
      return this.cart.order?.products || [];
    },
    isPreorder() {
      return this.getOrderPaymentStatus == Payment.PRE_ORDER;
    },
    order() {
      return this.cart.order;
    },
    taxesIncluded() {
      return this.store.isCannabisAllInTaxEnabled;
    },
    isLoading() {
      return this.getLoadingStatus.checkout;
    },
    isOpen: {
      get() {
        return this.cart.isOpen;
      },
      set(val) {
        if (!val) {
          this.flagCart();
        }
      }
    }
  },
  methods: {
    ...mapActions(useUserStore, ['flagCart', 'checkout']),
    ...mapActions(useCommonStore, [
      'selectProduct',
      'toggleDifferentKioskModal',
      'toggleConfirmOrderModal'
    ]),
    isSoldOut(productId) {
      return this.soldOutProducts.includes(productId);
    },
    onProductSelect(product) {
      this.selectProduct(product);
      this.flagCart();
    },
    onClose() {
      this.flagCart();
    },
    edit() {},
    continueShoppingHandler() {
      this.toggleDifferentKioskModal();
    },
    complete() {
      this.toggleConfirmOrderModal();
      // allocate batches (and check for errors (quantity finished))
      // if all good
      /**
       * show thank you page
       * log out user
       */
    }
  }
};
</script>

<style lang="scss" scoped>
@import './_cartPanel.scss';
</style>
