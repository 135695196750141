<template>
  <div class="inactivity">
    <v-idle v-if="isLoggedIn" @idle="onidle" @remind="onremind" :loop="true" :reminders="[reminder]" :wait="0"
      :duration="300" :events="['mousemove', 'keypress', 'onclick', 'click', 'touchstart', 'touchmove', 'scroll']" />
    <BaseModal v-if="inactivityModalOpen" @toggle-modal="toggleModal" title="Are you still there?" :subtitle="subTitle"
      :autoWidth="true">
      <div class="modal-actions">
        <Button @click="performLogout" label="LOGOUT" />
        <Button @click="() => inactivityModalOpen = false" :colorType="1" label="I'M HERE" />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/BaseModal/BaseModal.vue";
import Button from '@/components/Button/Button.vue';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
export default {
  components: {
    BaseModal,
    Button
  },
  data() {
    return {
      reminder: 30,
      inactivityModalOpen: false,
      countDown: 30,
      timer: null
    }
  },
  computed: {
    ...mapState(useUserStore, {
      isLoggedIn: 'isLoggedIn',
    }),
    subTitle() {
      return `If not, we will close this session in 00:${this.countDown.toString().padStart(2, '0')} seconds`
    }
  },
  methods: {
    ...mapActions(useUserStore, ['logout']),
    onidle() {
      if (!this.isLoggedIn) return;
      this.performLogout()
    },
    performLogout() {
      this.inactivityModalOpen = false
      clearInterval(this.timer)
      this.logout();
    },
    onremind(time) {
      if (!this.isLoggedIn) return;
      this.inactivityModalOpen = true;
      this.countDown = this.reminder;
      this.timer = setInterval(() => {
        this.countDown -= 1;
      }, 1000);
    },
    toggleModal(event) {
      this.inactivityModalOpen = event.isOpen;
      clearInterval(this.timer)
    },
  }
}
</script>

<style lang="scss" scoped>
@import './inactivity';
</style>