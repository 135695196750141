<template>
  <Skeleton v-if="isLoading" />
  <div v-else class="home">
    <SidePanel :product="productSelected" @onClose="onClose()" />
    <SortPanel />
    <FilterPanel />
    <CartPanel />
    <ContinueShoppingModal />
    <ConfirmOrderModal />
    <div v-if="!isCategorySelected">
      <CategoryOverview
        v-for="(category, index) of filteredCategories"
        :key="index"
        :name="category.name"
        :products="category.products"
      >
      </CategoryOverview>
    </div>
    <div v-else>
      <Category
        v-for="(category, index) of filteredCategories"
        :key="index"
        :name="category.name"
        :products="category.products"
      >
      </Category>
    </div>
    <NoData v-if="hasNoData" />
  </div>
</template>

<script>
import SidePanel from '@/components/SidePanel/SidePanel.vue';
import SortPanel from '@/components/SortPanel/SortPanel.vue';
import FilterPanel from '@/components/FilterPanel/FilterPanel.vue';
import CartPanel from '@/components/CartPanel/CartPanel.vue';
import CategoryOverview from './CategoryOverview/CategoryOverview.vue';
import ContinueShoppingModal from '@/components/ContinueShoppingModal/ContinueShoppingModal.vue';
import ConfirmOrderModal from '@/components/ConfirmOrderModal/ConfirmOrderModal.vue';
import Category from './Category/Category.vue';
import NoData from './NoData/NoData.vue';
import { mapState, mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { useUserStore } from '@/stores/user';
import Skeleton from './Skeleton/Skeleton.vue';
import { filterProducts } from '@/helpers/productHelper';
import { isBatch } from '@/helpers/filterHelper';

export default {
  components: {
    SidePanel,
    CategoryOverview,
    SortPanel,
    FilterPanel,
    CartPanel,
    Skeleton,
    NoData,
    Category,
    ContinueShoppingModal,
    ConfirmOrderModal
  },
  data() {
    return {
      isOpen: true,
      allProducts: []
    };
  },
  computed: {
    ...mapState(useCommonStore, {
      products: 'getProducts',
      productSelected: 'getProductSelected',
      categories: 'getCategories',
      getLoadingState: 'getLoadingState',
      actions: 'getActions',
      search: 'getSearch',
      selectedCategory: 'getSelectedCategory'
    }),
    ...mapState(useUserStore, {
      isLoggedIn: 'isLoggedIn'
    }),
    isLoading() {
      return (
        this.getLoadingState.getSettings || this.getLoadingState.getProducts
      );
    },
    hasNoData() {
      return (
        this.filteredCategories.filter(
          (c) => c.products && c.products.length > 0
        ).length == 0
      );
    },
    availableFilters() {
      return this.actions.filter.options;
    },
    selectedSorting() {
      return this.actions.sort.options;
    },
    isCategorySelected() {
      return (
        this.selectedCategory && this.selectedCategory.toLowerCase() != 'all'
      );
    },
    filteredCategories() {
      let filtered = [];
      this.allProducts = [];
      for (let i = 0; i < this.categories.length; i++) {
        const category = this.categories[i];

        const products = filterProducts(
          category,
          this.search,
          this.availableFilters,
          this.selectedSorting
        );
        filtered.push({
          name: category.name,
          products
        });
        if (products && Array.isArray(products)) {
          this.allProducts = this.allProducts.concat(products);
        }
      }

      if (this.allProducts.length == 1 && this.isBatchSearch) {
        this.selectProduct(this.allProducts[0]);
        setTimeout(() => {
          this.setSearch('');
        }, 1000);
      }
      return filtered;
    },
    isBatchSearch() {
      return isBatch(this.search);
    }
  },
  methods: {
    ...mapActions(useCommonStore, ['selectProduct', 'setSearch']),
    onClose() {
      this.selectProduct(null);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './_home.scss';
</style>
