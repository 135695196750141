<template>
  <div class="scan">
    <p class="title">Use Barcode</p>
    <p class="sub-title">For your convenice, you can use the barcode provided at checkin to order from the kiosk</p>
    <Button :colorType="1" @click="scanHandler" label="SCAN" />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'

export default {
  components: {
    Button
  },
  methods: {
    scanHandler() {
      this.$router.push({ name: 'scan' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_scan.scss';
</style>