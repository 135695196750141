<template>
  <div class="item-info" :class="{ 'item-info--single-column': !twoColumnsFormat }">
    <div class="item-info__left" v-if="twoColumnsFormat">
      <Header :name="name" :supplier="supplier" :grams="grams" />
      <div class="body">
        <p class="description" v-html="description"></p>
        <div class="info" v-if="isCannabis">
          <PercentageBar name="THC" :percentage="thc" />
          <PercentageBar name="CBD" :percentage="cbd" />
        </div>
      </div>
    </div>
    <div class="item-info__right">
      <Header class="header-one-column" v-if="!twoColumnsFormat" :name="name" :supplier="supplier" :grams="grams" />
      <img class="image" v-lazy="image" />
      <div class="info info--fullwidth" v-if="isCannabis && !twoColumnsFormat">
        <PercentageBar name="THC" :percentage="thc" />
        <PercentageBar name="CBD" :percentage="cbd" />
      </div>
      <Price :showTaxesIncluded="showTaxesIncluded" :value="price" :discount="discount" />
      <Quantity v-if="!isViewOnly" class="quantity" :value="quantity" @onValueUpdate="(val) => quantity = val" />
      <Button v-if="!isViewOnly" :label="btnLabel" class="add-to-cart" :colorType="1" @click="addToCart"
        :disabled="isLoading" :isLoading="isLoading" />
      <button v-if="isInCart" class="remove" @click="$emit('remove')">
        Remove
      </button>
    </div>

  </div>
</template>

<script>
import PercentageBar from '@/components/PercentageBar/PercentageBar.vue';
import Button from '@/components/Button/Button.vue'
import Price from './Price/Price.vue'
import Quantity from './Quantity/Quantity.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Header from './Header/Header.vue'

export default {
  components: {
    PercentageBar,
    Button,
    Price,
    Quantity,
    FontAwesomeIcon,
    Header
  },
  data() {
    return {
      quantity: 1
    }
  },
  props: {
    name: {
      type: String
    },
    description: {
      type: String
    },
    supplier: {
      type: String
    },
    grams: {
      type: Number
    },
    thc: {
      type: [Number, String]
    },
    cbd: {
      type: [Number, String]
    },
    price: {
      type: Number
    },
    image: {
      type: Object
    },
    showTaxesIncluded: {
      type: Boolean,
      default: false
    },
    isCannabis: {
      type: Boolean,
      default: false,
    },
    quantityInCart: {
      type: Number,
    },
    isInCart: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    discount: {
      type: Object,
    },
    twoColumnsFormat: {
      type: Boolean,
      default: true
    },
    isViewOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    btnLabel() {
      if (this.isInCart) {
        return 'UPDATE'
      }
      return 'ADD TO CART'
    },
  },
  methods: {
    addToCart() {
      let qty = this.quantity;
      if (this.quantityInCart) {
        qty = this.quantity - this.quantityInCart;
      }
      this.$emit('addToCart', qty)
    }
  },
  beforeMount() {
    if (this.quantityInCart) {
      this.quantity = this.quantityInCart;
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_body.scss';
</style>
