<template>
  <div class="input-icons">
    <!-- <i class="fa fa-user icon"></i> -->
    <!-- <i class="fa fa-magnifying-glass icon"></i> -->
    <font-awesome-icon class="icon icon--left" :icon="['fa', 'fa-magnifying-glass']" />
    <input ref="searchInput" class="input-field" type="text" placeholder="search" v-bind="$attrs" :value="value"
    @input="onInputHandler" />
    <font-awesome-icon v-if="showCancel" @click="onClear"  class="icon icon--right" :icon="['fa', 'fa-xmark']" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    value: {
      type: String
    },
  },
  computed: {
    showCancel() {
      return this.value.length > 0
    }
  },
  methods: {
    onClear() {
      this.$emit('clear')
    },
    onInputHandler(event) {
      this.$emit('update', event.target.value)
    },
    setFocus() {
      this.$refs.searchInput.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_search.scss';
</style>
