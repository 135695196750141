<template>
  <button class="cart-btn">
    <font-awesome-icon class="cart-btn__icon" :icon="['fa', 'fa-cart-shopping']" />
    <div class="cart-btn__quantity">
      <p>{{ count }}</p>
    </div>
  </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  components: {
    FontAwesomeIcon
  }
}
</script>

<style scoped lang="scss">
@import './_cartBtn.scss';
</style>
