<template>
  <div class="home-skeleton">
    <div class="product" v-for="i of 20" :key="i">
      <div class="image"></div>
      <div class="section"></div>
      <div class="section section--big"></div>
      <div class="section"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './_skeleton.scss';
</style>