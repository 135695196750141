<template>
  <div class="skeleton-nav">
    <div class="skeleton-nav__left">
      <div class="loading circle"></div>
      <div class="loading circle"></div>
    </div>
    <div class="skeleton-nav__center">
      <div class="loading search"></div>
    </div>
    <div class="skeleton-nav__right">
      <div class="loading circle"></div>
    </div>
  </div>
  <div class="categories">
    <div class="categories__scroll">
      <div class="category" v-for="i of 10" :key="i"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './_skeleton.scss';
</style>
