<template>
  <div class="category" v-if="products && products.length > 0">
    <div class="header">
      <div class="header__left">
        <div class="menu" @click="goToMenu">
          <font-awesome-icon class="menu__icon" :icon="['fa-solid', 'fa-chevron-left']" />
          <p>ALL</p>
        </div>
      </div>
      <div class="header__center">
        <p class="title">{{ name.toUpperCase() }}</p>
      </div>
    </div>
    <ProductsList :products="products" />
  </div>
</template>

<script>
import ProductsList from '@/components/ProductsList/ProductsList.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';

export default {
  props: {
    name: {
      type: String
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ProductsList,
    FontAwesomeIcon
  },
  methods: {
    ...mapActions(useCommonStore, ['selectCategory']),
    goToMenu() {
      this.selectCategory('All')
    }
  }
};
</script>

<style scoped lang="scss">
@import './_category.scss';
</style>
