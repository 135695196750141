<template>
  <div class="guest">
    <div class="body">
      <p class="title">Guest Info</p>
      <div class="form">
        <div class="form__section">
          <InputComponent :focus="true" label="Name" v-model:textValue="name" :placeholder="''" class="input" />
          <InputComponent label="Last Name Initial" v-model:textValue="lastName" :placeholder="''" class="input" />
        </div>
        <div class="form__section">
          <Dropdown label="Country" class="input" :default="defaultCountry" :options="countries"
            @input="$event => country = $event.code" />
          <Dropdown v-if="showState" label="State" class="input" :default="defaultState" :options="states"
            @input="$event => state = $event.code" />
        </div>
      </div>
      <Button :colorType="1" @click="checkInCustomerHandler" class="btn" :disabled="!isFormValid || isLoading"
        label="Confirm" :isLoading="isLoading"></Button>
    </div>
    <Button :colorType="3" class="go-back" @click="goHome" iconGroup="fas" iconName="arrow-left" label="Back"></Button>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'
import InputComponent from '@/components/Input/Input.vue'
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import { mapState, mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';
import states from '@/constants/states';
import countries from '@/constants/countries';

const US_CODE = 'US';

export default {
  components: {
    Button,
    InputComponent,
    Dropdown
  },
  data() {
    return {
      name: '',
      lastName: '',
      country: '',
      state: '',
      countries,
      states,
    }
  },
  computed: {
    ...mapState(useCommonStore, {
      store: 'getStore',
    }),
    ...mapState(useUserStore, {
      customerId: 'customerId',
      user: 'user',
      getOrderPaymentStatus: 'getOrderPaymentStatus',
      getLoadingStatus: 'getLoadingStatus'
    }),
    isLoading() {
      return this.getLoadingStatus.addGuestCustomer ||
        this.getLoadingStatus.createOrder ||
        this.getLoadingStatus.getOpenOrder;
    },
    defaultCountry() {
      const findCountry = this.countries.find((c) => c.code == US_CODE);
      return findCountry;
    },
    defaultState() {
      const storeState = this.store.state;
      const findState = this.states.find((s) => s.code == storeState);
      return findState;
    },
    showState() {
      if (this.country != US_CODE) return false;
      return true;
    },
    isFormValid() {
      const { name, lastName, country, state } = this.formatForm();

      if (!name || !lastName) return false;
      return true;
    }
  },
  methods: {
    ...mapActions(useUserStore, ['addGuestCustomer', 'getOpenOrder', 'createOrder', 'flagCart', 'setIsGuest']),
    formatForm() {
      const name = this.name.trim();
      let lastName = this.lastName.trim()

      if (lastName.length > 0) {
        lastName = lastName[0];
      }

      const country = this.country;
      const state = this.country == US_CODE ? this.state : ''

      return { name, lastName, country, state }
    },
    goHome() {
      this.$router.push({ name: 'signIn' })
    },
    async checkInCustomerHandler() {
      const { name, lastName, country, state } = this.formatForm();
      await this.addGuestCustomer(name, lastName, country, state)
        .catch((err) => {
          toast.error(err)
        })

      if (!this.user) return;

      this.setIsGuest(true);
      await this.getOpenOrder()
        .then(async (data) => {
          if (data) { // an order already exists. show that one? or start a new one
            if (data.isPreorder) {
              this.$router.push('/existingOrder');
            } else {
              this.$router.push('/')
              this.flagCart()
            }
          } else { // no order exists. then create a new one
            await this.createOrder()
              .then(() => {
                this.$router.push('/')
              })
              .catch((err) => {
                toast.error(err)
              })
          }
        })
        .catch((err) => {
          toast.error(err)
        })
    }
  },
  mounted() {
    this.state = this.defaultState;
    this.country = US_CODE;
  }
}
</script>

<style lang="scss" scoped>
@import './_guest.scss';
</style>