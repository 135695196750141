<template>
  <div class="input-icons">
    <p v-if="label" class="label" for="input">{{ label }}</p>
    <input ref="input" class="input-field" :style="{ textAlign: textAlign }" :type="type" :placeholder="placeholder"
      :value="textValue" @input="$emit('update:textValue', $event.target.value)" autocomplete="new-password" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    textValue: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    focus: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  mounted() {
    if (this.focus) {
      this.$refs.input.focus()
    }
  }
};
</script>

<style lang="scss" scoped>
@import './_input.scss';
</style>
