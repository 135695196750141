<template>
  <BaseModal v-if="isDifferentKioskModalOpen" @toggle-modal="toggleModal"
    title="Are you sure you want to continue your order on a different kiosk?" :autoWidth="true">
    <div class="modal-actions">
      <Button @click="toggleModal" label="CANCEL" />
      <Button @click="performLogout" :colorType="1" label="CONFIRM" />
    </div>
  </BaseModal>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import BaseModal from "@/components/BaseModal/BaseModal.vue";
import { useUserStore } from '@/stores/user';
import { useCommonStore } from '@/stores/common';
import { mapState, mapActions } from 'pinia';
export default {
  components: {
    Button,
    BaseModal
  },
  computed: {
    ...mapState(useCommonStore, {
      isDifferentKioskModalOpen: 'isDifferentKioskModalOpen'
    }),
  },
  methods: {
    ...mapActions(useUserStore, ['flagCart', 'logout']),
    ...mapActions(useCommonStore, ['toggleDifferentKioskModal']),
    performLogout() {
      this.isOpen = false;
      this.flagCart()
      this.logout()
      this.toggleDifferentKioskModal()
    },
    toggleModal() {
      this.toggleDifferentKioskModal()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_continueShoppingModal.scss';
</style>