<template>
  <div class="header">
    <Button @click="onClose()" :iconGroup="'fas'" :iconName="'xmark'" />
    <p class="title">FILTER</p>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';

export default {
  components: {
    Button
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    }
  }
};
</script>

<style lang="scss" scoped>
@import './_header.scss';
</style>
