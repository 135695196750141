<template>
  <div class="products-list">
    <ProductCard v-for="product of filteredProducts" :key="product._id" :product="product" @onClick="onClick(product)" />
    <div v-if="showNextPage" class="navigate navigate--right" @click="goNextPage">
      <font-awesome-icon :icon="['fa-solid', 'fa-chevron-right']" />
    </div>
    <div v-if="showPreviusPage" class="navigate navigate--left" @click="goPreviusPage">
      <font-awesome-icon :icon="['fa-solid', 'fa-chevron-left']" />
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard/ProductCard.vue';
import { mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  props: {
    products: {
      type: Object
    },
    itemsPerPage: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      currentPage: 0
    }
  },
  components: {
    ProductCard,
    FontAwesomeIcon
  },
  computed: {
    filteredProducts() {
      const from = this.currentPage * this.itemsPerPage;
      const to = from + this.itemsPerPage
      return this.products.slice(from, to)
    },
    showNextPage() {
      return ((this.currentPage + 1) * this.itemsPerPage) < this.products.length
    },
    showPreviusPage() {
      return this.currentPage > 0
    }
  },
  methods: {
    ...mapActions(useCommonStore, ['selectProduct']),
    goNextPage() {
      this.currentPage += 1;
    },
    goPreviusPage() {
      this.currentPage -= 1;
    },
    onClick(product) {
      this.selectProduct(product);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './_productsListCarousel.scss';
</style>
