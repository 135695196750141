<template>
  <div class="quantity">
    <Button class="quantity__btn" :disabled="value <= 1" iconGroup="fas" iconName="minus"
      @click="update('subtract')"></Button>
    <p class="quantity__value">{{ value }}</p>
    <Button class="quantity__btn" iconGroup="fas" iconName="plus" @click="update('add')"></Button>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'
export default {
  props: {
    value: {
      type: Number
    }
  },
  components: {
    Button
  },
  methods: {
    update(type) {
      if (type == 'add') {
        return this.$emit('onValueUpdate', this.value + 1)
      }
      if (type == 'subtract') {
        return this.$emit('onValueUpdate', this.value - 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_quantity.scss';
</style>
