import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home/Home.vue';
import ExistingOrder from '../views/ExistingOrder/ExistingOrder.vue';
import ThankYou from '../views/ThankYou/ThankYou.vue';
import Login from '../views/Login/Login.vue';

import SignIn from '../views/SignIn/SignIn.vue';
import Scan from '../views/SignIn/Scan/Scan.vue';
import Guest from '../views/SignIn/Guest/Guest.vue';
import Options from '../views/SignIn/Options/Options.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/signIn',
    component: SignIn,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'signIn',
        component: Options
      },
      {
        path: 'scan',
        name: 'scan',
        component: Scan
      },
      {
        path: 'guest',
        name: 'guest',
        component: Guest
      }
    ]
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    // import(/* webpackChunkName: "about" */ '../views/Scan/Scan.vue')
  },
  {
    path: '/existingOrder',
    name: 'existingOrder',
    component: ExistingOrder,
    meta: { requiresAuth: true },
  },
  {
    path: '/thankyou',
    name: 'thankYou',
    component: ThankYou,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});



router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
      return;
    }
  }

  next();
});

export default router;
