export const getFilters = (options: any, nodes: any): any => {
  const newAvailableFilters = JSON.parse(JSON.stringify(options));
  try {
    for (let group of nodes) {
      if (group.nodes) {
        for (let product of group.nodes) {
          const supplier =
            product.externalFields.supplier.shortNameOnlineStore ||
            product.externalFields.supplier.name;
          const variety = product.variety;
          if (
            !newAvailableFilters[1].items.find((i: any) => i.name == supplier)
          ) {
            newAvailableFilters[1].items.push({
              name: supplier,
              isSelected: false
            });
          }
          if (
            variety && variety.length > 0 &&
            !newAvailableFilters[0].items.find((i: any) => i.name == variety)
          ) {
            newAvailableFilters[0].items.push({
              name: variety,
              isSelected: false
            });
          }
        }
      }
    }
    newAvailableFilters[0].items = newAvailableFilters[0].items.sort(
      (a: any, b: any) => a.name.localeCompare(b.name)
    );
    return newAvailableFilters;
  } catch(err) {
    console.log(err)
  }
};

export const isBatch = (filter: string): boolean => {
  if (!filter) return false;
  // check if the batch is only number and can be shorter than 16
  if (filter.length < 16) return false;

  // return true only if it is a number that has length > 16
  const parsedFilter = parseInt(filter);
  if (!isNaN(parsedFilter)) return true;
  
  return false;
}