<template>
  <VueSidePanel v-model="isOpen" hide-close-btn side="right" :width="sidePanelWidth" panel-color="#1b1b1d">
    <template v-slot:header>
      <Header @onClose="onClose()" />
    </template>
    <template #default v-if="product != null">

      <Body class="side-panel-body " :name="productInfo.name" :description="productInfo.description"
        :grams="productInfo.grams" :supplier="productInfo.supplier" :thc="productInfo.thc" :cbd="productInfo.cbd"
        :price="productInfo.price" :image="productInfo.image" :isCannabis="productInfo.isCannabis"
        :showTaxesIncluded="showTaxesIncluded" @addToCart="addToCart" :isInCart="isInCart != null"
        :quantityInCart="isInCart?.quantity" @remove="remove" :isLoading="isLoading"
        :discount="product.discountInformation" :twoColumnsFormat="twoColumnsFormat" :isViewOnly="isViewOnly" />
    </template>
  </VueSidePanel>
</template>

<script>
import { VueSidePanel } from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';
import { mapState, mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { useUserStore } from '@/stores/user';
import { getImageObj, getPrice, formatSupplier } from '@/helpers/productHelper';
import Header from './Header/Header.vue'
import Body from './Body/Body.vue'
import { toast } from 'vue3-toastify';

export default {
  components: {
    VueSidePanel,
    Header,
    Body
  },
  data() {
    return {
      lastWidth: '90vw'
    };
  },
  props: {
    product: {
      type: Object
    }
  },
  computed: {
    ...mapState(useCommonStore, {
      store: 'getStore',
      getIsViewOnly: 'getIsViewOnly'
    }),
    ...mapState(useUserStore, {
      isLoggedIn: 'isLoggedIn',
      getProductsInCart: 'getProductsInCart',
      getLoadingStatus: 'getLoadingStatus'
    }),
    isOpen: {
      get() {
        return this.product != null;
      },
      set(val) {
        if (!val) {
          this.onClose();
        }
      }
    },
    isViewOnly() {
      return this.getIsViewOnly
    },
    isLoading() {
      return this.getLoadingStatus.updateCart;
    },
    showTaxesIncluded() {
      return this.productInfo.isCannabis && this.store.isCannabisAllInTaxEnabled
    },
    productInfo() {
      return {
        name: this.product.onlineStoreName,
        description: this.product.onlineStoreDescription,
        supplier: formatSupplier(this.product),
        grams: this.product.cannabisGramsUnit,
        thc: this.calculateChamicalValue('thc'),
        cbd: this.calculateChamicalValue('cbd'),
        price: getPrice(this.product), // TODO; change depending on saletype
        image: getImageObj(this.store, this.product),
        isCannabis: this.product.externalFields.group.isCannabis
      }
    },
    twoColumnsFormat() {
      return this.product && (this.productInfo.description == null || this.productInfo.description.trim().length > 0)
    },
    isInCart() {
      return this.getProductsInCart.find(p => p._id == this.product._id);
    },
    sidePanelWidth() {
      if (!this.product) return this.lastWidth;
      let val = '90vw';
      if (this.twoColumnsFormat) {
        this.lastWidth = val;
        return val;
      }
      val = '35vw';
      this.lastWidth = val;
      return val;
    },
  },
  methods: {
    ...mapActions(useUserStore, ['updateCart', 'flagCart']),
    onClose() {
      this.$emit('onClose');
    },
    calculateChamicalValue(info) {
      if (this.product[info]) return this.product[info];
      if (this.product?.items && this.product?.items?.length > 0) {
        let total = 0;
        let count = 0;
        this.product.items.forEach(i => {
          if (i.chemicalComposition) {
            count++;
            total += i.chemicalComposition[info] + i.chemicalComposition[`${info}a`] * 0.877
          }
        })
        return (total / count).toFixed(2)
      }
    },
    remove() {
      this.updateCart(this.product._id, -this.isInCart.quantity)
        .then(() => {
          this.$emit('onClose');
          this.flagCart()
        })
        .catch((err) => {
          toast.error(err)
        })
    },
    addToCart(quantity) {
      if (!this.isLoggedIn) {
        this.$router.push('/signIn')
      } else {
        this.updateCart(this.product._id, quantity)
          .then(() => {
            this.$emit('onClose');
            this.flagCart()
          })
          .catch((err) => {
            toast.error(err)
          })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import './_sidePanel.scss';

.body-test {
  background-color: red;
}
</style>
