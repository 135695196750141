<template>
  <VueSidePanel v-model="isOpen" hide-close-btn side="right" width="50vw" panel-color="#1b1b1d">
    <template v-slot:header>
      <Header @onClose="onClose()" />
    </template>
    <template #default>
      <div class="sort-container">
        <Button v-for="(option, index) of options" :key="index" @click="onSelectSort(option)" :highlight="option.sort">
          {{ option.name.toUpperCase() }}
          <span v-if="option.sort">
            <font-awesome-icon v-if="option.sort == 'asc'" :icon="['fa', 'fa-arrow-up']" />
            <font-awesome-icon v-if="option.sort == 'desc'" :icon="['fa', 'fa-arrow-down']" />
          </span>
        </Button>
      </div>
    </template>
  </VueSidePanel>

</template>

<script>
import { VueSidePanel } from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';
import { mapState, mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
import Header from './Header/Header.vue'
import Button from '@/components/Button/Button.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    VueSidePanel,
    Header,
    Button,
    FontAwesomeIcon
  },
  computed: {
    ...mapState(useCommonStore, {
      actions: 'getActions'
    }),
    options() {
      return this.actions.sort.options
    },
    isOpen: {
      get() {
        return this.actions.sort.isOpen;
      },
      set(val) {
        if (!val) {
          this.flagSort()
        }
      }
    },
  },
  methods: {
    ...mapActions(useCommonStore, ['flagSort', 'toggleAvailableSorting']),
    onSelectSort(sort) {
      this.toggleAvailableSorting(sort)
    },
    onClose() {
      this.flagSort()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_sortPanel.scss';
</style>
