<template>
  <Header v-if="isHome" />
  <Inactivity />
  <router-view />
</template>

<script setup>
import { useCommonStore } from '@/stores/common';
import { ref, watch, computed } from 'vue';


let barcode = ref('');

const commonStore = useCommonStore();
const search = computed(() => commonStore.search);

watch(search, (newVal) => {
  if (!newVal || newVal.trim().length == 0) {
    barcode.value = ''
  }
})

addEventListener('keyup', function (ev) {
  const digit = ev.key;
  if (digit.toLowerCase() == 'enter' || barcode.value.length >= 16) {
    setSearch();
    return;
  }
  barcode.value += digit;
});

function setSearch() {
  let isnum = /^\d+$/.test(barcode.value.trim());
  if (isnum) {
    commonStore.setSearch(barcode.value);
    setTimeout(() => {
      commonStore.setSearch('');
      barcode.value = '';
    }, 1000);
  } else {
    barcode.value = ''
  }
}
</script>

<script>
import Header from '@/components/Header/Header.vue';
import { mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { toast } from 'vue3-toastify';
import Inactivity from '@/components/Inactivity/Inactivity.vue';

export default {
  components: {
    Header,
    Inactivity
  },
  computed: {
    isHome() {
      return this.$route.name === 'home';
    }
  },
  methods: {
    ...mapActions(useCommonStore, ['fetchProducts', 'getStoreSettings'])
  },
  created() {
    const token = localStorage.getItem('token');
    if (!token) return;
    this.getStoreSettings()
      .then(() => {
        this.fetchProducts().catch((err) => {
          toast.error(err);
          this.$router.push('/login');
        });
      })
      .catch((err) => {
        toast.error(err);
        this.$router.push('/login');
      });
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
