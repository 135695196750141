<template>
  <div class="dropdown">
    <p class="label" v-if="label">{{ label }}</p>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
      <div class="selected" :class="{ open: open }" @click="open = !open">
        {{ selected.label }}
      </div>
      <div class="items" :class="{ selectHide: !open }">
        <div v-for="(option, i) of options" :key="i" @click="
          selected = option;
        open = false;
        $emit('input', option);
        ">
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: Object,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
          ? this.options[0]
          : null,
      open: false,
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style lang="scss" scoped>
@import './dropdown';
</style>