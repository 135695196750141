<template>
  <div class="header">
    <div class="header__left">
      <h2 class="name">{{ name }}</h2>
      <h3 class="supplier">{{ formattedSupplier }}</h3>
    </div>
    <div class="grams" v-if="grams">
      <p class="grams__value">{{ grams }}</p>
      <p class="grams__unit">grams</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    },
    grams: {
      type: Number
    },
    supplier: {
      type: String
    },
  },
  computed: {
    formattedSupplier() {
      if (!this.supplier) return '-'
      return this.supplier.toUpperCase()
    },
  }
}
</script>

<style lang="scss" scoped>
@import './_header.scss';
</style>
