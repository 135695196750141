<template>
  <div class="thank-you">
    <div class="body">
      <font-awesome-icon class="icon" :icon="['fas', 'check']" />
      <p class="title">Thank you</p>
      <p class="sub-title">Please proceed to the registers for the payment</p>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapActions } from 'pinia';
import { useUserStore } from '@/stores/user';

export default {
  components: {
    FontAwesomeIcon
  },
  methods: {
    ...mapActions(useUserStore, ['logout']),
  },
  mounted() {
    setTimeout(() => {
      this.logout()
      this.$router.push('/')
    }, 10000)
  }
}
</script>

<style lang="scss" scoped>
@import './_thankyou.scss';
</style>
