<template>
  <BaseModal
    v-if="isConfirmOrderModalOpen"
    @toggle-modal="toggleModal"
    title="Are you sure you want to confirm the order? Once confirmed, it can be modified only by a staff member."
    :autoWidth="true"
  >
    <div class="modal-actions">
      <Button @click="toggleModal" label="CANCEL" />
      <Button @click="confirmOrderHandler" :colorType="1" label="CONFIRM" />
    </div>
  </BaseModal>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';
import { useUserStore } from '@/stores/user';
import { useCommonStore } from '@/stores/common';
import { mapState, mapActions } from 'pinia';
import { toast } from 'vue3-toastify';

export default {
  components: {
    Button,
    BaseModal
  },
  computed: {
    ...mapState(useCommonStore, {
      isConfirmOrderModalOpen: 'isConfirmOrderModalOpen'
    })
  },
  methods: {
    ...mapActions(useUserStore, ['flagCart', 'logout', 'checkout']),
    ...mapActions(useCommonStore, ['toggleConfirmOrderModal']),
    confirmOrderHandler() {
      this.isOpen = false;
      this.checkout()
        .then(() => {
          this.flagCart();
          this.toggleConfirmOrderModal();
          this.$router.push('/thankyou');
        })
        .catch((err) => {
          toast.error(err);
          this.toggleConfirmOrderModal();
        });
    },
    toggleModal() {
      this.toggleConfirmOrderModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import './_confirmOrderModal.scss';
</style>
