<template>
  <div class="product-card" @click="onClick()">
    <img class="image" v-lazy="imgObj" />
    <div class="variety" v-if="product.variety">
      <p class="variety__value">{{ product.variety }}</p>
    </div>
    <div v-else class="variety__empty"></div>
    <p class="name">{{ product.onlineStoreName }}</p>
    <div class="sub-name">
      <p class="supplier">{{ supplier }}</p>
      <p class="gram" v-if="product.cannabisGramsUnit">{{ product.cannabisGramsUnit }}g</p>
    </div>
    <div class="info" :class="{ 'info--border': isCannabis }">
      <div class="info__data" v-if="isCannabis">
        <p class="info__data-name">THC</p>
        <p class="info__data-value">{{ convertToNumber(product.thc) }}%</p>
      </div>
      <div class="info__data" v-if="isCannabis">
        <p class="info__data-name">CBD</p>
        <p class="info__data-value">{{ convertToNumber(product.cbd) }}%</p>
      </div>
    </div>
    <div class="footer">
      <p class="price" :class="{ 'price--line-through': discount && discount.lineThrough }">{{ formatPrice(price) }}</p>
      <div v-if="isViewOnly"></div>
      <button v-else-if="!isInCart" class="add" @click.stop="add" :disabled="isLoading">
        <font-awesome-icon v-if="showLoading" :icon="['fa', 'fa-circle-notch']" spin />
        <font-awesome-icon v-else :icon="['fas', 'plus']" />
      </button>
      <button v-else class="add-quantity" @click.stop="onClick">{{ isInCart?.quantity }}</button>
    </div>
    <p class="price--discount" v-if="discount">{{ discount.text }}</p>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { getImageObj, getPrice, formatSupplier } from '@/helpers/productHelper';
import { formatPrice } from '@/helpers/general'
import { useCommonStore } from '@/stores/common';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formatPrice,
      clicked: false
    }
  },
  computed: {
    ...mapState(useCommonStore, {
      store: 'getStore',
      getLoadingStatus: 'getLoadingStatus',
      getIsViewOnly: 'getIsViewOnly'
    }),
    ...mapState(useUserStore, {
      isLoggedIn: 'isLoggedIn',
      getProductsInCart: 'getProductsInCart',
      getLoadingStatus: 'getLoadingStatus'
    }),
    showLoading() {
      return this.isLoading && this.clicked;
    },
    isViewOnly() {
      return this.getIsViewOnly
    },
    isLoading() {
      return this.getLoadingStatus.updateCart
    },
    supplier() {
      return formatSupplier(this.product);
    },
    imgObj() {
      return getImageObj(this.store, this.product);
    },
    price() {
      return getPrice(this.product); // TODO; change depending on saletype
    },
    isCannabis() {
      return this.product.externalFields.group.isCannabis
    },
    isInCart() {
      return this.getProductsInCart.find(p => p._id == this.product._id);
    },
    discount() {
      return this.product.discountInformation
    },
  },
  methods: {
    ...mapActions(useUserStore, ['updateCart', 'flagCart']),
    onClick() {
      this.$emit('onClick', {});
    },
    convertToNumber(value) {
      if (!value) return 0;
      const valueFloat = parseFloat(value);
      if (isNaN(value)) return 0;
      return value;
    },
    add() {
      if (!this.isLoggedIn) {
        this.$router.push('/signIn')
      } else {
        this.clicked = true;
        this.updateCart(this.product._id, 1)
          .then(() => {
            this.flagCart()
          })
          .catch((err) => {
            toast.error(err)
          })
          .finally(() => {
            this.clicked = false;
          })
      }

    }
  }
};
</script>

<style lang="scss" scoped>
@import './_productCard.scss';
</style>
