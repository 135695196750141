<template>
  <div class="customer">
    <p class="title">Returning Customers</p>
    <p class="sub-title">Sign in to speed up the checkout process and collect points</p>
    <Input v-model:textValue="email" class="input-item" placeholder="email" type="email" autocomplete="off" />
    <Input v-model:textValue="password" class="input-item" placeholder="password" type="password" autocomplete="off" />
    <Button :colorType="1" @click="signInHandler" label="SIGN IN" :disabled="disabled" :isLoading="isLoading" />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'
import Input from '@/components/Input/Input.vue'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';

export default {
  components: {
    Button,
    Input
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapState(useUserStore, {
      getLoadingStatus: 'getLoadingStatus'
    }),
    validForm() {
      if (!this.email) return false;
      if (!this.password) return false;

      const { email, password } = this.formatForm()

      return email.length > 0 && password.length > 0
    },
    disabled() {
      return !this.validForm || this.isLoading;
    },
    isLoading() {
      return this.getLoadingStatus.userLogin ||
        this.getLoadingStatus.createOrder ||
        this.getLoadingStatus.getOpenOrder;
    }
  },
  methods: {
    ...mapActions(useUserStore, ['userLogin', 'getOpenOrder', 'createOrder']),
    formatForm() {
      const email = this.email.trim().toLowerCase();
      const password = this.password.trim();
      return { email, password }
    },
    async getOrder() {
      await this.getOpenOrder()
        .then(async (data) => {
          if (data) { // an order already exists. show that one? or start a new one
            if (data.isPreorder) {
              this.$router.push('/existingOrder');
            } else {
              this.$router.push('/')
              this.flagCart()
            }
          } else { // no order exists. then create a new one
            await this.createOrder()
              .then(() => {
                this.$router.push('/')
              })
              .catch((err) => {
                toast.error(err)
              })
          }
        })
        .catch((err) => {
          toast.error(err)
        })
    },
    async signInHandler() {
      const { email, password } = this.formatForm();
      await this.userLogin(email, password)
        .then(() => {
          this.getOrder()
        })
        .catch((err) => {
          toast.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_customer.scss';
</style>