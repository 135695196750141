<template>
  <div class="percentage-bar">
    <p class="name">{{ name }}</p>
    <div class="meter">
      <span v-bind:style="barWidthCalculated"></span>
    </div>
    <p class="percentage">{{ formattedPercentage }}</p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    percentage: {
      type: [Number, String]
    }
  },
  computed: {
    formattedPercentage() {
      const parsed = parseFloat(this.percentage);
      const value = this.limitNumberWithinRange(parsed, 0, 100)
      return this.formatPercentage(value);
    },
    barWidthCalculated: function () {
      return {
        width: this.formattedPercentage,
      };
    },

  },
  methods: {
    formatPercentage(value) {
      return `${value}%`
    },
    limitNumberWithinRange(num, min, max) {
      if (num > max) return max;
      if (num < min) return min;
      return num;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './_percentageBar.scss';
</style>
