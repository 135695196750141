import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import VueLazyLoad from 'vue3-lazyload';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from 'axios'
import Vidle from 'v-idle-3';
//@ts-ignore
import DisableAutocomplete from 'vue-disable-autocomplete';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFilter,
  faSort,
  faXmark,
  faCartShopping,
  faMagnifyingGlass,
  faPlus,
  faMinus,
  faArrowUp,
  faArrowDown,
  faBarcode,
  faCheck,
  faArrowLeft,
  faCircleNotch,
  faTrashCan,
  faAnglesDown,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faFilter,
  faSort,
  faXmark,
  faCartShopping,
  faMagnifyingGlass,
  faPlus,
  faArrowUp,
  faArrowDown,
  faMinus,
  faBarcode,
  faCheck,
  faArrowLeft,
  faCircleNotch,
  faTrashCan,
  faAnglesDown,
  faChevronRight,
  faChevronLeft,
);

const pinia = createPinia();

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    router.push('/login')
  }
  return Promise.reject(error)
})


createApp(App)
  .use(pinia)
  .use(Vue3Toastify, {
    autoClose: 5000,
    position: 'top-center',
    closeButton: false,
    theme: 'dark'
  } as ToastContainerOptions)
  .use(VueLazyLoad, {
    preLoad: 1.5,
    attempt: 2
  })
  .use(DisableAutocomplete)
  .use(Vidle)
  .use(router)
  .mount('#app');
