import { defineStore } from 'pinia';
import serializeNodes from '@/helpers/serializeNodes';
import axiosService from '@/services/axiosService';
import { getFilters } from '@/helpers/filterHelper';

export const useCommonStore = defineStore('common', {
  state: () => ({
    loadingState: {
      getSettings: false,
      login: false,
      getProducts: false
    },
    categories: [] as any[],
    products: [] as unknown[],
    productSelected: null as unknown | null,
    store: {} as any,
    organization: {} as unknown,
    selectedCategory: 'All',
    search: '' as string,
    modals: {
      differentKioskOpen: false,
      confirmOrderOpen: false
    },
    actions: {
      filter: {
        isOpen: false,
        options: [
          {
            name: 'Variety',
            items: []
          },
          {
            name: 'Cultivator',
            items: []
          }
        ]
      },
      sort: {
        isOpen: false,
        options: [
          {
            code: 'onlineStoreName',
            name: 'Name',
            sort: undefined
          },
          {
            code: 'variety',
            name: 'Variety',
            sort: undefined
          },
          {
            code: 'thc',
            name: 'THC',
            sort: undefined
          },
          {
            code: 'cbd',
            name: 'CBD',
            sort: undefined
          },
          {
            code: 'cannabisGramsUnit',
            name: 'Grams',
            sort: undefined
          },
          {
            code: 'price',
            name: 'Price',
            sort: undefined
          }
        ]
      }
    }
  }),
  getters: {
    isDifferentKioskModalOpen: (state): any => state.modals.differentKioskOpen,
    isConfirmOrderModalOpen: (state): any => state.modals.confirmOrderOpen,
    getLoadingState: (state): any => state.loadingState,
    getSearch: (state): string => state.search,
    getActions: (state): any => state.actions,
    getIsViewOnly: (state): any => state.store?.viewOnly,
    getStore: (state): any => state.store,
    getStoreState: (state): string => state.store?.state,
    getCategoriesName: (state): string[] => {
      return state.categories.map((c) => c.name);
    },
    getCategories: (state): unknown[] => {
      const selectedCategory = state.selectedCategory;
      if (selectedCategory === 'All') return state.categories;

      const filtered = state.categories.filter(
        (c) => c?.name == selectedCategory
      );
      return filtered;
    },
    getSelectedCategory: (state): string => state.selectedCategory,
    getProducts: (state): unknown[] => state.products,
    getProductSelected: (state): unknown => state.productSelected
  },
  actions: {
    async fetchProducts() {
      this.loadingState.getProducts = true;
      try {
        axiosService.get(
          '/getHierarchy?saleType=recreational',
          (response: any, error: any) => {
            this.loadingState.getProducts = false;
            if (error) {
              return;
            }
            const data = response.data.body?.nodes || [];
            const { groups, nodes } = serializeNodes(data);
            this.categories = nodes;
            this.actions.filter.options = getFilters(
              this.actions.filter.options,
              data
            );
          }
        );
      } catch (err) {
        this.loadingState.getProducts = false;
        console.log(err);
      }
    },
    async getStoreSettings(): Promise<void> {
      return new Promise((resolve, reject): void => {
        try {
          this.loadingState.getSettings = true;
          axiosService.get('/getSettings', (response: any, error: any) => {
            this.loadingState.getSettings = false;
            if (error) {
              reject();
              return;
            }
            const { store, organization } = response.data.body;
            this.store = store;
            this.organization = organization;
            resolve();
          });
        } catch (err) {
          this.loadingState.getSettings = false;
        }
      });
    },
    async login(username: string, password: string): Promise<void> {
      return new Promise((resolve, reject): void => {
        try {
          const payload = {
            username,
            password
          };
          this.loadingState.login = true;
          axiosService.post(
            '/login',
            payload,
            async (response: any, error: any) => {
              this.loadingState.login = false;
              if (error) {
                reject(error);
                return;
              }
              let data = response.data.body;
              localStorage.setItem('token', data.token);
              await this.getStoreSettings();
              await this.fetchProducts();
              resolve();
            }
          );
        } catch (err) {
          this.loadingState.login = false;
        }
      });
    },
    logout() {
      localStorage.removeItem('token');
    },
    selectProduct(product: unknown) {
      this.productSelected = product;
    },
    flagFilter() {
      this.actions.filter.isOpen = !this.actions.filter.isOpen;
    },
    flagSort() {
      this.actions.sort.isOpen = !this.actions.sort.isOpen;
    },
    selectCategory(category: string) {
      this.selectedCategory = category;
    },
    setSearch(search: string) {
      this.search = search;
    },
    toggleAvailableFilter(payload: any) {
      payload.isSelected = !payload.isSelected;
    },
    toggleAvailableSorting(payload: any) {
      switch (payload.sort) {
        case 'asc':
          payload.sort = 'desc';
          break;

        case 'desc':
          payload.sort = undefined;
          break;

        default:
          payload.sort = 'asc';
          break;
      }
    },
    toggleDifferentKioskModal() {
      this.modals.differentKioskOpen = !this.modals.differentKioskOpen;
    },
    toggleConfirmOrderModal() {
      this.modals.confirmOrderOpen = !this.modals.confirmOrderOpen;
    }
  }
});
