<template>
  <div class="summary">
    <p v-if="taxesIncluded" class="taxes-included">All Taxes Included</p>
    <div class="row">
      <p>Sub Total</p>
      <p>{{ formatPrice(subTotal) }}</p>
    </div>
    <div class="row">
      <p>Discount</p>
      <p>{{ discount > 0 ? '-' : '' }} {{ formatPrice(discount) }}</p>
    </div>
    <div class="row">
      <p>Tax</p>
      <p>{{ formatPrice(tax) }}</p>
    </div>
    <div class="row row--total">
      <p>Total</p>
      <p>{{ formatPrice(total) }}</p>
    </div>
    <Button v-if="isPreorder" :colorType="1" @click="edit">Edit</Button>
    <div v-else class="actions" :class="{ 'actions--single': isGuest }">
      <Button v-if="!isGuest" class="complete" :colorType="4" @click="continueShopping" :disabled="isLoading"
        :isLoading="isLoading">Continue
        on different kiosk</Button>
      <Button class="complete" :colorType="1" @click="complete" :disabled="!canComplete || isLoading"
        :isLoading="isLoading">Checkout</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'
import { formatPrice } from '@/helpers/general'

export default {
  props: {
    total: {
      type: Number
    },
    discount: {
      type: Number,
      default: 0
    },
    subTotal: {
      type: Number,
      default: 0
    },
    tax: {
      type: Number,
      default: 0
    },
    isPreorder: {
      type: Boolean,
      default: false
    },
    canComplete: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    taxesIncluded: {
      type: Boolean,
      default: false
    },
    isGuest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formatPrice
    }
  },
  components: {
    Button
  },
  methods: {
    complete() {
      this.$emit('complete')
    },
    edit() {
      this.$emit('edit')
    },
    continueShopping() {
      this.$emit('continueShopping')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_summary.scss';
</style>