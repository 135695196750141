<template>
  <div class="guest">
    <p class="title">New Customers</p>
    <p class="sub-title">Proceed to checkout as a guest. No account will be required or saved.</p>
    <Button :colorType="1" @click="guestHandler" label="CONTINUE AS GUEST" />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'

export default {
  components: {
    Button
  },
  methods: {
    guestHandler() {
      this.$router.push({ name: 'guest' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_guest.scss';
</style>