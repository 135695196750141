<template>
  <div class="login">
    <div class="body">
      <h1 class="title">Login</h1>
      <InputComponent :focus="true" v-model:textValue="username" :placeholder="'username'" class="input"
        textAlign="center" />
      <InputComponent v-model:textValue="password" type="password" :placeholder="'password'" class="input"
        textAlign="center" />
      <Button class="action" :colorType="1" @click="onLogin" :disabled="!isValid || isLoading" label="LOGIN"
        :isLoading="isLoading"></Button>
    </div>
  </div>
</template>

<script>
import { useCommonStore } from '@/stores/common'
import { mapActions, mapState } from 'pinia';
import { toast } from 'vue3-toastify';
import Button from '@/components/Button/Button.vue'
import InputComponent from '@/components/Input/Input.vue'

export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },
  components: {
    Button,
    InputComponent
  },
  computed: {
    ...mapState(useCommonStore, ['getLoadingState']),
    isValid() {
      return this.username.length > 0 && this.password.length > 0
    },
    isLoading() {
      return this.getLoadingState.login
    }
  },
  methods: {
    ...mapActions(
      useCommonStore, ['login']
    ),
    onLogin() {
      this.login(this.username, this.password)
        .then(() => {
          this.$router.push('/')
        })
        .catch((err) => {
          toast.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_login.scss';
</style>
