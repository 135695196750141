<template>
  <div class="scan">
    <div class="body">
      <font-awesome-icon class="icon" :icon="['fas', 'barcode']" />
      <p class="title">Scan</p>
      <InputComponent :focus="true" v-model:textValue="scan" :placeholder="'Scan here...'" class="input" />
      <Button :colorType="1" @click="checkScan" class="btn" :disabled="disableBtn || isLoading" label="Scan"
        :isLoading="isLoading"></Button>
    </div>
    <Button :colorType="3" class="go-back" @click="goHome" iconGroup="fas" iconName="arrow-left" label="Back"></Button>
    <div class="arrow-scan" v-if="showArrow">
      <font-awesome-icon class="arrow-scan__icon" :icon="['fas', 'angles-down']" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'
import InputComponent from '@/components/Input/Input.vue'
import { mapState, mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    Button,
    InputComponent,
    FontAwesomeIcon
  },
  data() {
    return {
      scan: ''
    }
  },
  watch: {
    scan(newVal) {
      if (newVal.length == 8) {
        this.checkScan()
      }
    }
  },
  computed: {
    ...mapState(useCommonStore, {
      store: 'getStore',
    }),
    ...mapState(useUserStore, {
      customerId: 'customerId',
      user: 'user',
      getOrderPaymentStatus: 'getOrderPaymentStatus',
      getLoadingStatus: 'getLoadingStatus'
    }),
    showArrow() {
      return this.store.showKioskScanArrow;
    },
    logo() {
      if (this.store.logo) {
        return this.store.logo.secure_url;
      }
      return "";
    },
    disableBtn() {
      return this.scan.length < 8;
    },
    isLoading() {
      return this.getLoadingStatus.getQueueCustomer ||
        this.getLoadingStatus.createOrder ||
        this.getLoadingStatus.getOpenOrder;
    }
  },
  methods: {
    ...mapActions(useUserStore, ['getQueueCustomer', 'getOpenOrder', 'createOrder', 'flagCart']),
    goHome() {
      this.$router.push({ name: 'signIn' })
    },
    async checkScan() {
      await this.getQueueCustomer(this.scan.toUpperCase())
        .catch((err) => {
          toast.error(err)
        })

      if (!this.user) return;

      await this.getOpenOrder()
        .then(async (data) => {
          if (data) { // an order already exists. show that one? or start a new one
            if (data.isPreorder) {
              this.$router.push('/existingOrder');
            } else {
              this.$router.push('/')
              this.flagCart()
            }
          } else { // no order exists. then create a new one
            await this.createOrder()
              .then(() => {
                this.$router.push('/')
              })
              .catch((err) => {
                toast.error(err)
              })
          }
        })
        .catch((err) => {
          toast.error(err)
        })
    }
  },
}
</script>

<style lang="scss" scoped>
@import './_scan.scss';
</style>