<template>
  <div class="price">
    <p class="price__value"
      :class="{ 'price__value--line-through': discount && discount.lineThrough, 'price__value--padding': !showTaxesIncluded }">
      {{
        valueFormatted
      }}</p>
    <p v-if="showTaxesIncluded" class="price__extra">Taxes Included</p>
    <p class="price--discount" v-if="discount">{{ discount.text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number
    },
    showTaxesIncluded: {
      type: Boolean,
      default: false
    },
    discount: {
      type: Object,
    }
  },
  computed: {
    valueFormatted() {
      const value = this.value || 0;

      return `$${value.toFixed(2)}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_price.scss';
</style>