<template>
  <div class="existing-order">
    <div class="body">
      <p class="title">Looks like you have an order already</p>
      <p class="sub-title">Please proceed to the registers to apply any changes or pick up your order</p>
      <!-- <div class="actions">
              <button class="option" @click="createNewOrder">Start<br />new order</button>
              <button class="option" @click="editOrder">Edit<br />existing order</button>
            </div> -->
      <Button @click="cancel" :colorType="1" label="Ok" class="ok"></Button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';
import Button from '@/components/Button/Button.vue'

export default {
  components: {
    Button
  },
  methods: {
    ...mapActions(useUserStore, ['logout', 'cancelOrder', 'createOrder', 'reverseOrder', 'flagCart']),
    async createNewOrder() {
      // archive existing order
      await this.cancelOrder()
        .then(() => {
          // create a new order
          this.createOrder()
            .then(() => {
              this.$router.push('/')
            })
            .catch((err) => {
              toast.error(err)
            })
        })
        .catch((err) => {
          toast.error(err)
        })
    },
    editOrder() {
      this.reverseOrder()
        .then(() => {
          this.$router.push('/')
          this.flagCart()
        })
        .catch((err) => {
          toast.error(err)
        })
    },
    cancel() {
      this.logout()
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_existingOrder.scss';
</style>