<template>
  <div class="category" v-if="products && products.length > 0">
    <div class="header">
      <p @click="setCategoryHandler" class="category__title__value">
        {{ name.toUpperCase() }}
      </p>
      <div class="category-info">
        <p class="category-info__count">
          {{ pluralize(products.length, 'Product') }}
        </p>
        <p class="category-info__action" @click="setCategoryHandler">See All</p>
      </div>
    </div>
    <ProductsListCarousel :products="products" />
  </div>
</template>

<script>
import ProductsListCarousel from '@/components/ProductsListCarousel/ProductsListCarousel.vue';
import { mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';

export default {
  props: {
    name: {
      type: String
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapActions(useCommonStore, ['selectCategory']),
    pluralize(count, noun, suffix = 's') {
      return `${count} ${noun}${count !== 1 ? suffix : ''}`;
    },
    setCategoryHandler() {
      this.selectCategory(this.name);
    }
  },
  components: {
    ProductsListCarousel
  }
};
</script>

<style scoped lang="scss">
@import './_categoryOverview.scss';
</style>
