<template>
  <div class="product">
    <div v-if="isSoldOut" class="sold-out">Sold Out</div>
    <img class="image" v-lazy="productInfo.image" />
    <div class="product-body">
      <div class="product-body__content">
        <p class="name">{{ productInfo.name }}</p>
        <p class="quantity">x {{ product.quantity }}</p>
      </div>
    </div>
    <div class="price">
      <p :class="{ 'price--line-through': isDiscounted }">
        {{ formatPrice(productInfo.price) }}
      </p>
      <p v-if="isDiscounted" class="price--discounted">
        {{ formatPrice(productInfo.salePrice) }}
      </p>
    </div>
  </div>
</template>

<script>
import { getImageObj, getPrice, formatSupplier } from '@/helpers/productHelper';
import { formatPrice } from '@/helpers/general';

export default {
  props: {
    product: {
      type: Object
    },
    isSoldOut: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      formatPrice
    };
  },
  computed: {
    productInfo() {
      return {
        name: this.product.name,
        description: this.product.onlineStoreDescription,
        price: getPrice(this.product), // TODO; change depending on saletype
        image: getImageObj(this.store, this.product),
        salePrice: this.product.salePrice
      };
    },
    isDiscounted() {
      return this.productInfo.price > this.productInfo.salePrice;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './_product.scss';
</style>
