<template>
  <VueSidePanel v-model="isOpen" hide-close-btn side="right" width="60vw" panel-color="#1b1b1d">
    <template v-slot:header>
      <Header @onClose="onClose()" />
    </template>
    <template #default>
      <div v-for="filter of options" :key="filter.name" class="options">
        <div class="options__title">{{ filter.name }}</div>
        <div class="options__buttons">
          <Button v-for="filter of sort(filter.items)" :key="filter.name" @click="selectFilter(filter)"
            :highlight="filter.isSelected">
            {{ filter.name.toUpperCase() }}
          </Button>
        </div>
      </div>
    </template>
  </VueSidePanel>

</template>

<script>
import { VueSidePanel } from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';
import { mapState, mapActions } from 'pinia';
import { useCommonStore } from '@/stores/common';
import Header from './Header/Header.vue'
import Button from '@/components/Button/Button.vue'

export default {
  components: {
    VueSidePanel,
    Header,
    Button
  },
  computed: {
    ...mapState(useCommonStore, {
      actions: 'getActions'
    }),
    options() {
      return this.actions.filter.options
    },
    isOpen: {
      get() {
        return this.actions.filter.isOpen;
      },
      set(val) {
        if (!val) {
          this.flagFilter()
        }
      }
    },
  },
  methods: {
    ...mapActions(useCommonStore, ['flagFilter', 'toggleAvailableFilter']),
    onClose() {
      this.flagFilter()
    },
    selectFilter(filter) {
      this.toggleAvailableFilter(filter)
    },
    sort(items) {
      let sorted = items.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0));
      return sorted;
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_filterPanel.scss';
</style>
