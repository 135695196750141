import SALE_TYPE from '@/types/SaleType';
import {isBatch} from './filterHelper';

const getImage = function (
  store: any,
  product: any,
  type = 'imageThumbnail',
  fallBackType = 'imageNormal'
): any {
  try {
    if (product[type] && product[type].secure_url) {
      return product[type].secure_url;
    }
    if (product[fallBackType] && product[fallBackType].secure_url) {
      return product[fallBackType].secure_url;
    }
    return store.productImagePlaceholderThumbnail.secure_url;
  } catch (error) {
    return '';
  }
};
const getImagePlaceHolder = function (store: any, type = 'thumbnail'): any {
  try {
    const thumbnail = store?.productImagePlaceholderThumbnail;
    const normal = store?.productImagePlaceholder;

    if (!thumbnail && !normal) return '';

    if (type == 'normal') {
      if (!normal) return thumbnail.secure_url;
      return normal.secure_url;
    }

    return thumbnail.secure_url;
  } catch (err) {
    return '';
  }
};

export const getImageObj = (store: any, product: any): any => {
  const src = getImage(store, product);
  const placeholder = getImagePlaceHolder(store);
  return {
    src,
    error: placeholder,
    loading: placeholder
  };
};

export const formatSupplier = (product: any): string => {
  const data = [];
  if (product.brandName) {
    data.push(product.brandName.toUpperCase())
  }

  const supplierInfo = product?.externalFields?.supplier?.shortNameOnlineStore;
  if (supplierInfo) {
    data.push(supplierInfo.toUpperCase());
  }

  return data.join(' - ');
};

export const getPrice = (
  product: any,
  saleType = SALE_TYPE.RECREATIONAL
): number => {
  if (saleType == SALE_TYPE.RECREATIONAL) {
    return product.recreationalPrice;
  } else {
    return product.medicalPrice;
  }
};

const filterFunction = (f: any) => f.isSelected;
const sortNumberField = (a: any, b: any, sort: any): any => {
  if (sort.sort == 'asc') {
    return +a[sort.code] > +b[sort.code] ? -1 : 1;
  }
  if (sort.sort == 'desc') {
    return +a[sort.code] < +b[sort.code] ? -1 : 1;
  }
};
export const filterProducts = (
  category: any,
  filter: any,
  availableFilters: any,
  selectedSorting: any
): any => {
  let filtered = category.nodes;
  const saleType = localStorage.getItem('saleType');
  try {
    const varietySelectedFilters =
      availableFilters[0].items.filter(filterFunction);
    const cultivatorSelectedFilters =
      availableFilters[1].items.filter(filterFunction);
    if (filter && filter.length > 3) {
      if (isBatch(filter)) {
        if (category.nodes) {
          filtered = category.nodes.filter((n: any) => n.batches.includes(filter));
        }
      } else {
        const normalizedFilter = filter.toLowerCase();
        if (category.nodes) {
          filtered = category.nodes.filter(
            (n: any) =>
              n.onlineStoreName.toLowerCase().includes(normalizedFilter) ||
              n.variety.toLowerCase().includes(normalizedFilter) ||
              n.brandName.toLowerCase().includes(normalizedFilter) ||
              n.externalFields.supplier.name
                .toLowerCase()
                .includes(normalizedFilter)
          );
        }
      }
    }

    if (varietySelectedFilters.length > 0) {
      filtered = filtered.filter((p: any) =>
        varietySelectedFilters.find((f: any) => p.variety == f.name)
      );
    }

    if (cultivatorSelectedFilters.length > 0) {
      filtered = filtered.filter((p: any) =>
        cultivatorSelectedFilters.find(
          (f: any) =>
            p.externalFields.supplier.shortNameOnlineStore == f.name ||
            p.externalFields.supplier.name == f.name
        )
      );
    }

    for (let sort of selectedSorting) {
      if (!filtered) return;
      filtered = [...filtered].sort((a, b) => {
        switch (sort.code) {
          case 'price':
            const priceA =
              saleType == 'medical' ? a.medicalPrice : a.recreationalPrice;
            const priceB =
              saleType == 'medical' ? b.medicalPrice : b.recreationalPrice;
            if (sort.sort == 'asc') {
              return priceA > priceB ? -1 : 1;
            }
            if (sort.sort == 'desc') {
              return priceA < priceB ? -1 : 1;
            }
            break;

          case 'thc':
            return sortNumberField(a, b, sort);
            break;

          case 'cbd':
            return sortNumberField(a, b, sort);
            break;

          default:
            if (sort.sort == 'asc') {
              return a[sort.code] > b[sort.code] ? -1 : 1;
            }
            if (sort.sort == 'desc') {
              return a[sort.code] < b[sort.code] ? -1 : 1;
            }
            break;
        }
      });
    }
  } catch (error) {
    console.log('error in filtering');
    console.log(error);
  }

  return filtered;
};
