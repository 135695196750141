import axios from 'axios';
import config from '@/constants/config';

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common['store-id'] = config.STORE_ID;
axios.defaults.headers.common['organization-id'] = config.ORGANIZATION_ID;
axios.defaults.headers.common['source'] = 'kiosk';

export default {
  get: function (url: string, callback: any) {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
    axios
      .get(url)
      .then((response) => {
        try {
          if (
            response.data &&
            response.data.value &&
            response.data.value.message
          ) {
            callback(undefined, response.data.value.message);
            return;
          }
        } catch (error: any) {
          // Vue.$log.debug("unable to read server error:", error);
        }

        try {
          callback(response);
        } catch (error) {
          // Vue.$log.error("rest call error in callback:", error);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Vue.$log.error(
          //   "rest call failed (error status, error text):",
          //   error.response.status,
          //   error.response.statusText
          // );
        } else {
          // Vue.$log.error("rest call failed");
        }
        if (error.response?.data) {
          if (error.response.data.message) {
            callback(undefined, error.response.data.message);
          } else {
            callback(undefined, error.response.data);
          }
        } else {
          callback(undefined, error.response?.statusText);
        }
      });
  },
  post: function (url:string, body: any, callback: any) {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
    axios
      .post(url, body)
      .then((response) => {
        try {
          if (
            response.data &&
            response.data.value &&
            response.data.value.message
          ) {
            // Vue.$log.error(
            //   "rest call error in callback:",
            //   response.data.value.message
            // );
            callback(undefined, response.data.value.message);
            return;
          }
        } catch (error) {
          // Vue.$log.debug("unable to read server error:", error);
        }

        try {
          callback(response);
        } catch (error) {
          // Vue.$log.error("rest call error in callback:", error);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Vue.$log.error(
          //   "rest call failed (error status, error text):",
          //   error.response.status,
          //   error.response.statusText
          // );
        } else {
          // Vue.$log.error("rest call failed");
          callback(undefined, 'rest call failed');
        }
        if (error.response.data) {
          callback(undefined, error.response.data);
        } else {
          callback(undefined, error.response.statusText);
        }
      });
  }
};
