import SaleType from "@/types/SaleType";

export const getOrderTypeFromCustomer = (customerAccountType: string): string => {
  if(!customerAccountType) return SaleType.RECREATIONAL
  if (customerAccountType == SaleType.MEDICAL_AND_RECREATIONAL) return SaleType.RECREATIONAL;

  return SaleType.RECREATIONAL
}


export const isRecreationalCustomer = (customer: any): boolean => {
  if (!customer) return true;
  if (!customer.accountType) return true;

  if (customer.accountType == SaleType.MEDICAL) return false;

  return true;
}